@import 'variables';

#find-loads-ver-2-page {
  $time-line-width: 16px;

  %base-time-line {
    background-image: linear-gradient(
        $carrier-light-2-grey,
        $carrier-light-2-grey
    );
    background-size: 2px 100%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
  }

  //Dashed circle
  %base-dashed-step-bar {
    background-image: url('/assets/images/azure-map/multi-stop-marker.svg');
    background-repeat: no-repeat;
    box-sizing: border-box;
    top: 0;
    margin: auto;
    height: 16px;
    position: absolute;
    width: 16px;
  }

  .stop-details {
    background: linear-gradient(0deg, $carrier-off-white, $carrier-off-white),
    $carrier-off-white;
    font-style: normal;
    font-weight: 400;
    margin: 0 18px 0 18px;
    padding: 18px 0 18px 0;

    .stop {
      border-radius: 2px;
      color: $carrier-mid-3-grey;
      display: flex;
      flex-direction: inline-block;

      .aside-section-left {
        //Timeline design
        .time-line {
          display: flex;
          flex-direction: row;
          justify-content: center;
          position: relative;
          width: $time-line-width;
          margin: 0 14px 0 14px;
        }
      }

      .article-section {
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-wrap: wrap;

        .title {
          display: flex;
          flex-direction: row;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 1.4px;
          margin-bottom: 1px;

          .pickup-dropoff {
            text-transform: uppercase;
          }
        }

        .warehouse-address {
          font-size: 14px;
          line-height: 19px;
          color: $carrier-med-grey;
          margin-bottom: 4px;

          > span {
            font-weight: 600;
          }
        }

        .timing-component {
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 3px;
        }

        .stop-appointment {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 8px;
        }

        .warehouse-hours {
          font-size: 13px;
          line-height: 18px;
          margin-top: 8px;
        }

        .stop-appointment,
        .warehouse-hours {
          svg {
            height: 12px;
            margin-right: 4px;
            fill: $carrier-mid-2-grey;

            @include browser-only(IE-only) {
              width: 12px;
            }
          }
        }
      }

      .aside-section-right {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        padding: 0 12px 0 18px;
        width: 160px;

        span {
          white-space: nowrap;
        }
      }

      &:not(:last-child) {
        .time-line {
          @extend %base-time-line;
        }

        .main-info {
          border-bottom: 1px solid #cccccc;
        }

        .warehouse-hours {
          padding-bottom: 22px;
        }
      }
    }

    & > .stop {
      .step-bar {
          background-repeat: no-repeat;
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: text-top;
          position: absolute;
      }
      &:first-child {
        .step-bar {
          background-image: url('/assets/images/azure-map/origin-marker.svg');
        }
      }

      &:last-child {
        .step-bar {
          background-image: url('/assets/images/azure-map/destination-marker.svg');
          width: 20px;
          height: 20px;
        }
      }

      &:not(:first-child):not(:last-child) {
          .step-bar {
            @extend %base-dashed-step-bar;
          }
        }
    }

    //Data hidden and view all component
    .view-all .stop,
    .hidden-cards .stop {
      .time-line {
        @extend %base-time-line;
      }

      .shrink {
        margin-bottom: 22px;
      }

      .warehouse-hours.has-shrink {
        padding-bottom: 9px;
      }

      .step-bar {
        @extend %base-dashed-step-bar;
      }
    }

    .view-all .stop {
      height: 100%;
    }

    //FIND LOCATION, DRIVER, AND NO-WRAP IN TABLES AND MAKE SURE THEY DONT WRAP
    .assign-driver {
      text-decoration: none;
      text-align: center;
      display: block;

      &:hover {
        cursor: pointer;
      }
    }

    #expand-check {
      display: none;
  
      & + .view-all .stop {
        height: 56px;
      }
  
      & + .view-all {
        height: 56px;
        opacity: 1;
        overflow: hidden;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
  
      & + .view-all + .hidden-cards {
        max-height: 0px;
        opacity: 0;
        overflow: hidden;
        -webkit-transition: all 0.0s;
        -moz-transition: all 0.0s;
        -ms-transition: all 0.0s;
        -o-transition: all 0.0s;
        transition: all 0.0s;
      }
    }
  
    #expand-check:checked {
      & + .view-all {
        height: 0px;
        transform: rotate3d(1, 0, 0, 0.3turn);
        opacity: 0;
      }
  
      & + .view-all + .hidden-cards {
        max-height: 99999px;
        opacity: 1;
      }
    }
  }
}
