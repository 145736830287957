.cap-labeled {
  margin: 4px 28px 14px 18px;

  &.Key {
    color: #B54D08;
  }

  &.Core {
    color: #005CB9;
  }

  .cap-label-icon {
    width: 22px;
    height: 21px;
    float: left;
    margin-right: 10px;
  }
}
