@import "variables";

.button-area {
  border-top: 1px solid $carrier-light-2-grey;
  background-color: $white;
  padding: 0 28px 0 18px;

  .time-left {
    font-weight: $bold;
    color: $count-down-timer;
  }

  .offer-buttons {
    display: flex;
    column-gap: 12px;

    .btn-block {
      margin: 14px 0 14px 0;
    }
  }

  .contact-phone {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $carrier-navigation-blue;
    font-size: 14px;
    font-weight: $bold;
    margin: 14px 0 14px 0;
    width: 100%;

    .glyphicon {
      font-size: 15px;
    }

    span {
      margin-right: 5px;
    }
  }

  .carrier-qualification,
  .carrier-validation {
    text-align: center;
    padding: 16px 0;
    line-height: 16px;
  }
}