@import 'variables';

.commodity-section {
  color: $carrier-dark-2-grey;
  padding: 0 30px 32px 30px;

  .separator-line {
    margin-bottom: 24px;
  }

  .title {
    font-size: 16px;
    font-weight: $bold;
    line-height: 22px;
  }

  .commodity-info {
    display: flex;
    flex-direction: row;
    margin: 12px 0 8px;

    .commodity-name {
      font-size: 14px;
      font-weight: $normal;
    }

    .multiple-commodities {
      color: $de-emphasized-font-color;
      font-size: 13px;
      font-weight: $normal;
      margin-left: 5px;
      vertical-align: middle;
    }
  }
}
