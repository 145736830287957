@import 'variables';

.contact-phone {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $carrier-navigation-blue;
  font-size: 14px;
  font-weight: $bold;
  margin: 14px 0 14px 0;
  width: 100%;

  .glyphicon {
    font-size: 15px;
  }
  
  span {
    margin-right: 5px;
  }
}