.cap-restricted {
  margin: 14px 0 14px 0;

  &.Key {
    color: #B54D08;
  }

  &.Core {
    color: #005CB9;
  }

  .cap-lock {
    height: 100%;
    width: 16px;
    float: left;
    margin-right: 10px;
  }
}
