@import 'variables';

.reloads-section {
  color: $carrier-dark-2-grey;
  padding: 0 30px 32px 30px;

  .separator-line {
    margin-bottom: 24px;
  }

  .title {
    font-size: 16px;
    font-weight: $bold;
    line-height: 22px;
  }
}
