@import 'variables';

#find-loads-ver-2-page .find-loads-detail, .reload-detail {
  .title-bar {
    background-color: $white;
    border-bottom: 1px solid $carrier-light-2-grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 41px;
    padding: 8px 17px;
    width: 100%;

    h2 {
      flex: 1;

      .reloads-link {
        float: right;
        color: $chr-active-blue;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;

        .arrow-down-icon:before {
          background-image: url('/assets/images/arrow-down.svg');
          content: '';
          display: inline-block;
          margin-left: 6px;
          width: 20px;
          height: 15px;
          background-repeat: no-repeat;
          vertical-align: middle;
        }
      }
    }

    .close-button {
      color: $carrier-dark-2-grey;
      font-size: $font-size-lg;
      background-color: transparent;
      margin-left: $space-xs;

      &:focus {
        outline: unset;
        border: $focus-state-color solid 2px;
      }
    }
  }
}
