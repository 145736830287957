@import 'variables';

.cap-locked {
  line-height: 21px;
  margin-top: 5px;

  @include respond-below(sm) {
    margin-top: 0px;
  }

  .lock-icon {
    width: 16px;
    margin-right: 5px;
  }

  &.Key {
    color: #B54D08;
  }

  &.Core {
    color: #005CB9;
  }
}
