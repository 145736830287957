@import 'variables';

#find-loads-ver-2-page,
.js-offers-list {
  .reload-results {
    margin: 12px 0 8px;

    .reload-item {
      background: $white;
      border: 1px solid $carrier-light-2-grey;
      display: grid;
      column-gap: $space-sm;
      margin-bottom: 4px;
      grid-template-columns: 1fr;
      grid-template-areas:
        "pills" "actions" "locations" "requirements";
      padding-left: 10px;
      padding-right: 10px;

      &.clickable:hover {
        cursor: pointer;
        border: 1px solid $carrier-light-2-grey;
        box-sizing: border-box;
        box-shadow: $card-shadow;
        position: relative;
      }

      .action-area {
        margin-bottom: 10px;
      }

      .reload-item-section {
        padding: 10px;
      }

      .reload-pill-area {
        grid-area: pills;
        margin-top: 8px;
      }

      .reload-item-locations {
        grid-area: locations;
        background-color: $carrier-off-white;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .arrow {
          display: none;
        }

        .reload-item-location {
          padding-left: 24px;
          position: relative;

          &:before {
            background-color: $white;
            border: 2px solid;
            border-radius: 50%;
            content: '';
            display: block;
            height: 14px;
            left: 0;
            position: absolute;
            top: 4px;
            width: 14px;
          }

          &.js-load-origin {
            &:before {
              border-color: $carrier-blue-location-pin;
            }

            &:after {
              background-color: $carrier-grey-location-pin;
              content: '';
              display: block;
              height: 100%;
              left: 6px;
              position: absolute;
              top: 18px;
              width: 2px;
            }
          }

          &.js-load-destination:before {
            border-color: $carrier-grey-location-pin;
          }
        }
      }

      .reload-item-requirements {
        grid-area: requirements;
        gap: 28px;

        .stops {
          text-transform: lowercase;
        }

        >div {
          min-width: 0; // trunctation for flex child
          flex-basis: 90px;

          &.load-equipment-type {
            flex: 1;
            width: 100px;
            display: block;
          }
        }

        display: block;

        >div,
        div {
          width: auto;
          display: inline;

          &.load-equipment-type {
            width: auto;
          }

          span {
            color: #363636;
            display: inline-block;
            font-size: 14px;
            padding-right: 20px;
            position: relative;

            &:after {
              background-color: $carrier-grey-location-pin;
              content: '';
              display: block;
              height: 80%;
              position: absolute;
              right: 10px;
              top: 10%;
              width: 1px;
            }
          }
        }

        >div:last-child>div:last-child {
          span:after {
            display: none;
          }
        }
      }

      .reload-item-actions {
        grid-area: actions;
        display: contents;
        flex-direction: column;
        margin-top: 0px;

        button {
          text-align: right;
        }

        .js-primary-action {
          color: $carrier-navigation-blue;
          font-weight: $bold;
        }

        .offer-amount {
          display: none;
        }

        .time-left {
          color: $count-down-timer;
        }

        .offer-status {
          .offer-status-accepted {
            color: $carrier-mid-3-grey;
            font-weight: $bold;
            font-size: 0.75rem;
            letter-spacing: 0.05em;
          }

          .offer-status-label {
            color: $carrier-mid-3-grey;
            font-size: 0.75rem;
          }

          .accepted-offer-checkmark,
          .countered-offer-icon {
            align-content: center;
            margin-right: 4px;
            width: 16px;
            height: 16px;
          }
        }

        >div {
          text-align: right;
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: flex-end;

          .offer-amount {
            display: block;
          }

          .offer-status>label {
            display: flex;
          }

          .js-offer-status {
            display: flex;
          }

          .js-load-rate {
            align-self: flex-start;
            text-align: left;
            grid-column: 1/2;
          }

          .js-primary-action {
            justify-self: flex-end;
          }
        }
      }
    }
  }
}