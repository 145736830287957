.cap-label {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  text-align: center;
  white-space: nowrap;
  padding: 4px 8px;
  height: 24px;
  font-weight: 600;
  margin: 0 16px 8px 0;

  &.KEY {
    color: #B54D08;
    border: 1px solid #B54D08;
    background: #FFD579;
  }

  &.CORE {
    color: #004986;
    border: 1px solid #004986;
    background: #F1F8FF;
  }
  .cap-icon {
      width: 15px;
      height: 100%;
      vertical-align: middle;
      margin-right: 4px;
  }
}
