@import 'variables';

#find-loads-ver-2-page, .js-offers-list {
  .find-loads-result-location, .reload-item-location {
    .location-deadhead-group {
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;

      .location-deadhead-distance {
        flex-basis: 70px;
        flex-shrink: 0;

        @include respond-below(xs) {
          margin-left: auto;
        }
      }
    }

    .time {
      display: flex;
      flex-wrap: wrap;
      gap: 0px 8px;
    }
  }

  .reload-item-location .location-deadhead-group .location-deadhead-distance{
    margin-left: auto;
  }
}
