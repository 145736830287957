@import 'variables';

#find-loads-ver-2-page {
  .load-map-modal {
    .modal-dialog {
      height: 100%;
      width: 100%;
      margin: 0;

      @include respond-above(lg_two_columns_cuttoff) {
        height: 90vh;
        width: 90vw;
        margin: 30px auto;
      }

      .modal-content {
        height: 100%;
        width: 100%;
      }

      .modal-header {
        padding: 0;
      }

      .modal-body {
        height: calc(100% - 42px); // subtracts header height

        .azure-map {
          height: 100%;
        }
      }
    }
  }

  .load-map-modal-btn {
    color: $carrier-navigation-blue;
    line-height: 24px;
    margin: 0 16px 8px 16px;

    &:before {
      background-image: url('/assets/images/launch.svg');
      content: '';
      display: inline-block;
      margin-right: 4px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      vertical-align: top;
    }

    &:hover,
    &:active,
    &:focus {
      color: #004362; // match svg fill color

      &:before {
        background-image: url('/assets/images/launch_hover.svg');
      }
    }
  }
}

 // specific issue when maps modal renders inside of load details modal
.load-map-modal-open {
  #find-loads-ver-2-page {
    .chr-modal.load-details {
     .find-loads-detail {
        > .title-bar,
        > .button-area {
          display: none;
        }
      }
    }
  }
}
