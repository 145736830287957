@import 'variables';
@import 'media-queries';

.requirements-section {
  display: flex;
  flex-direction: column;
  margin: 24px 0 24px 0;
  width: 100%;
  padding: 0 30px 0 30px;

  .title {
    color: $carrier-med-grey;
    font-weight: $bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
  }

  .section-content {
    .requirement {
      display: flex;
      flex-direction: column;

      .requirement-label,
      .value {
        letter-spacing: 0px;
      }

      .requirement-label {
        color: $carrier-mid-3-grey;
        font-size: 13px;
        line-height: 18px;
        max-width: 125px;
      }

      .value {
        color: $carrier-med-grey;
        line-height: 19px;
        font-size: 14px;
        max-width: 100%;

        span {
          white-space: normal;

          small {
            font-weight: normal;
          }
        }
      }
    }
  }

  //two columns only
  @include respond-below(xl) {
    .section-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 22px 0px;
    }
  }

  @include respond-above(xl) {
    .section-content {
      display: grid;
      grid-template-columns: repeat(auto-fill, 120px);
      gap: 22px 18px;
    }
  }
}
