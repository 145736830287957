@import 'variables';

.VIEW_ALL_RELOADS {
  #VIEW_ALL_RELOADS-label {
    .title {
      font-size: 16px;
      font-weight: $bold;
      line-height: 22px;
    }
  }

  .modal-header {
    padding-bottom: 0;
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal-footer {
    padding-top: 0;
  }
}
