@import 'variables';

.reloads-more-info-popover-icon {
  align-content: center;
  margin-left: 4px;
  margin-bottom: 4px;
}

.Popover {
  .Popover-body {
    width: 280px !important;
  }
}
