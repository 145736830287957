@import 'variables';

.notes-section-preview {
    margin-top: 10px;

    .label {
        color: $carrier-mid-3-grey;
        font-size: 13px;
        line-height: 18px;
        max-width: 125px;
        font-weight: $normal;
        padding: 2px 0;
    }

    .shipment-note-content {
        overflow-y: auto;
        max-height: 100px;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-word;

        .shipment-note {
            color: $de-emphasized-font-color;
            font-size: 14px;
            vertical-align: middle;
            margin: 4px 0;
        }
    }
}